import React from "react"

import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"


class WissenwertesPage extends React.Component {
  render() {

    return (<Layout>
      <SEO title="Wissenwertes" />
      <section className="page-section">
        <h1>Wissenwertes Content</h1>
      </section>
    </Layout>)
  }
}

export default WissenwertesPage